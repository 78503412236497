import React, { Component } from 'react'

export default class MoonlitWay extends Component {
    render() {
        return (
            <div>
                <h1 style={{color: 'white'}}>Moonlit Way</h1>
            </div>
        )
    }
}

/**
Lighting up the path
Our way

Stay deep 
Stay sharp
Let the horse pull the cart
Let the wagon twists the wheels
To the whispers of the heart
The moon spills  light now
Cutting through the dark
Lighting up the path 
Our way
Its lighting up the path 
Our  way

Its lighting up the path 
Our  way

Stay deep
Stay sharp 
What was once called art
Got banished to the fields
And then lost in the stars
A little bit left
Still smuggled in a cart
Traveling a moonlit way
Traveling a moonlit way

Cause trust in crown
Is falling away
Trust in  republic 
Is falling away
Trust in dollar 
Is falling away
Is falling away
Is falling away


Deep state 
Deep start
Like trust pulled apart
Now we've traveled here together
And we'll never be apart
And the moons light
Shine's bright
Drowning out the stars
Traveling a moonlit way

Stay deep 
Stay sharp
Let the horse pull the cart
Let the wagon twists the wheels
To the whispers of the heart
The moon spills  light now
Cutting through the dark
Lighting up the path 
Our way
Lighting up the path 
Our way
Lighting up the path 
Our way
Lighting up the path 
Our way

Lighting up the path
Our way
Lighting up the path
Our way
 */
