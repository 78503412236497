import React, { useEffect } from "react";
import { Reset } from "styled-reset";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";
import NavBar from './components/Nav/NavBar'
import WeAre from "./components/Poem/WeAre";
import FreeAssange from "./components/Poem/FreeAssange";
import MoonlitWay from "./components/Poem/MoonlitWay";
import StandWith from "./components/Poem/StandWithMe";
import WeKnow from "./components/Poem/WeKnow";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function App() {
  return (
    <div >

          <Router>
          <ScrollToTop>

            <Switch>
                <Route path="/weare">
                  <WeAre/>
                </Route>
                <Route path="/freeassange">
                  <FreeAssange/>
                </Route>
                <Route path="/moonlitway">
                  <MoonlitWay/>
                </Route>
                <Route path="/standwith">
                  <StandWith/>
                </Route>
                <Route path="/weknow">
                  <WeKnow/>
                </Route>
                <Route path="/">
                  <WeAre />
                </Route>
              </Switch>
            </ScrollToTop>
          </Router>
    </div>
  );
}

export default App;


{/* <Reset /> */}
{/* <NavBar/>     */}