import React, { Component } from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import styled from "styled-components";
import media from "styled-media-query";
import Logo from "./../../res/images/Logo_SpheresCreative_Nav.png";
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';
import TagManager from 'react-gtm-module'


const Container = styled.div`
  border-bottom: 1px white solid;
`;

let HeaderLogoImage = styled.div`
  background: ${(props) => `url(${Logo})`};
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
    margin-left: 60px;
    /* margin-top: 15px; */
    height: 51px;
    width: 268px;
    background-repeat: no-repeat;
    align-content: center;
    transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
    margin-left: 5px;
    height: 38px;
    width: 200px;
  `}

  ${media.between("medium", "large")`
  margin-left: 10px;
  `}

  ${media.greaterThan("large")`
  `}
`;


const HeaderLogo = styled.div`
  justify-self: right;
  align-self: center;
`;

const NavListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;

  ${media.lessThan("medium")`
  flex-direction: column;
  `}

  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const IconContainer = styled.div`
/* border: 1px green dashed; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  width: 300px;
  height: 60px;
  align-content: center;
  ${media.between("medium", "large")`
  /* flex-direction: column; */
  `}

  ${media.greaterThan("large")`
  `}
`;

const List = styled.li`
display: flex;
    justify-content: center;
  margin: 0;
  padding: 0;
  ${media.lessThan("medium")`
  margin-top: 30px;
  `}
`;

const NavItemTitle = styled.p`
  /* border: 1px blue dashed; */
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-decoration: none;
  margin-right: 70px;
  color: white;
  font-weight: medium;
  text-shadow: 2px 2px 8px black;
  padding-top: 20px;
  &:hover {
    text-shadow: 0px 0px 8px turquoise;
  }
  
  ${media.lessThan("medium")`
  padding-top: 0px;
  font-size: 1rem
  margin-right: 0px;
  `}

  ${media.between("medium", "large")`
    font-size: 1rem
  `}

  ${media.greaterThan("large")`
  `}
`;


export default class BootNav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  setNavClose = () => {
    this.setState({ navExpanded: false });
  }

  handleContactLink = () => {

    }

    render() {
        return (
            <Container id="top">
                <Navbar collapseOnSelect onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="navbar-custom" expand="lg" variant="dark" style={{paddingBottom: "20px", paddingTop: "20px"} }>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                          <Nav className="mr-auto">
                          </Nav>

                          <Nav onClick={this.setNavClose} style={{justifyContent: "center"}}>
                          <NavListContainer>
                          <List>
                              <Link to="/weare"  style={{ textDecoration: 'none'}}>
                                <NavItemTitle>WE ARE</NavItemTitle>
                              </Link>
                          </List>

                          <List>
                              <Link to="/freeassange"  style={{ textDecoration: 'none'}}>
                                <NavItemTitle>FREE ASSANGE</NavItemTitle>
                              </Link>
                          </List>

                          <List>
                              <Link to="/moonlitway"  style={{ textDecoration: 'none'}}>
                                <NavItemTitle>MOONLIT WAY</NavItemTitle>
                              </Link>
                          </List>

                          <List>
                              <Link to="/standwith"  style={{ textDecoration: 'none'}}>
                                <NavItemTitle>STAND WITH</NavItemTitle>
                              </Link>
                          </List>

                          <List>
                              <Link to="/weknow"  style={{ textDecoration: 'none'}}>
                                <NavItemTitle>WE KNOW</NavItemTitle>
                              </Link>
                          </List>
              
                          </NavListContainer>
                          </Nav>
                          </Navbar.Collapse>
                          </Navbar>
                          </Container>
                          )
                        }
                      }
