import React, { Component } from 'react'

export default class FreeAssange extends Component {
    render() {
        return (
            <div>
                <h1 style={{color: 'white'}}>Free Assange</h1>
            </div>
        )
    }
}


 
/*
I heard once that America
Stood for freedom and for truth
With proud army and brave navy 
Yet  somethings gone crazy
Since Julien Assange told the truth 

He told the truth
He told the truth
Julian Assange just told the truth
Now if you know you care 
Then whisper everywhere
Free Assange! He told the truth

He told the truth
He told the truth
Become Assange and tell the truth!
Let him know you care 
Paint this everywhere
Free Assange! He  told the truth

Wikileaks once showed us all
What gets hidden from view
With an army and a navy 
In a war that's only crazy
Where Wikileaks found the truth

Find the truth 
Find the truth
Dig it up and find the truth  
If you look it's there
It's written everywhere
Free Assange! He found the truth

Find the  truth
Find  the truth
We're all Assange is the truth
When you know you care
Sing this everywhere
Free Assange! He told the truth

Find your truth
Find your  truth
Look deep inside to find your truth
When you know you're there
Please stand and declare
Free all those who tell the truth
**/


