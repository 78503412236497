import React, { Component } from 'react'

export default class WeKnow extends Component {
    render() {
        return (
            <div>
                <h1 style={{color: 'white'}}>We Know</h1>
            </div>
        )
    }
}


/**
 Now we know, yeah we know
That all these Covid questions, 
Stories and illusions  
Are Dividing society
We know, yeah we know 
With days now growing shorter 
I watched my people shackled up
As they sat and watched TV
 
We know, yeah we know
A Hypersonic Empire 
Could release a virus
Which spreads across the sea
And we know, yeah we know
If there were food dye in the answer
That could light you up from space 
Then they could watch you on TV

Now we know, we know
That inside of language
Is how we describe our lives 
As a cultural tapestry
Now we know, yeah we know
If we decode lips movement
And were watching you from space
We'd read your lips from a TV 

Now we know, we know
Freedoms from the passport
Soon to be a microchip
A gift from a loving Beast
And we know, yeah we know 
That if you're implanted
We'd be watching you from space
Called Virtual Reality

Now we know, we know
Our leaders may be shackled
Prisoners of their own crimes
Recorded on TV
Now we know, yeah we know
That if you value Freedom
Then you'll find an answer
To what's in the vaccine?
What's in the vaccine?

What's in the vaccine?
And can you see it on TV?
What's in the vaccine?
And can you see it on TV?
What's in the vaccine?
And can't you see it on TV?

What's in the vaccine?
And can't you see it on TV?
What's in the vaccine?
And can't you see it on TV?
What's in the vaccine?
And can't you see it on TV?

And can't you see it on TV?
  
 */