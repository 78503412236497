import React, { Component } from 'react'

export default class StandWith extends Component {
    render() {
        return (
            <div>
                <h1 style={{color: 'white'}}>Stand With Me</h1> 
            </div>
        )
    }
}


/**
 Stand with me
Cause power lies under our feet
Which watering should grow
Deep roots that spring from branch to leaf  
Then standing here, towering
Over Earth which holds space beneath
Just Breath, oh Breath
Visions revealed if you'll stand with me
So breath, oh breath. 
Air that's crisp and clear where sound flows sweet
And we'll  see, oh see
Cause Vision gets  revealed 
If you'll stand with me
Let's go 

Would you stand with me
In the underside unseen
Under endless skies of eternity
In the  Mushroom forest trees
Now from tree to tree
Let mighty roots 
Reach out beneath
And so deep, so deep
Visions revealed if you'll stand with me
So breath, oh breath
Air that's crisp and clear and sound grows sweet  
And just see, oh see 
Cause Vision gets revealed
If you'll  stand with me

Won't you stand with
Won't you stand with
Won't you stand with me
And Vision that's revealed
 
Won't you stand with
Won't you stand with
Won't you stand with me

 */