import React, { Component } from "react";
import styled from "styled-components";
import Image4 from "./../../res/images/Moon.png";
import Image3 from "./../../res/images/Assange_Freed.png";
import Image2 from "./../../res/images/Assange_Freed_moon.png";
import Image from "./../../res/images/bg_starrySky5.png";
import media from "styled-media-query";


const Grid = styled.div`
  display: grid;
  overflow: hidden;
  grid-template: auto / 100vw;
`;

const OuterContainer = styled.div`
/* border: 1px red dashed; */
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: center;
  ${media.lessThan("medium")`
      /* screen width is less than 768px (medium) */
      flex-direction: column;

  `}
  ${media.between("medium", "large")`
      /* screen width is between 768px (medium) and 1170px (large) */
      flex-direction: column;

;  `}
`;

const LandingContainer = styled.div`
/* border: 1px green dashed; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  margin-top: 60px;
  ${media.lessThan("medium")`
      /* screen width is less than 768px (medium) */
      margin-top: 30px;
  `}
  ${media.between("medium", "large")`
      /* screen width is between 768px (medium) and 1170px (large) */
      margin-top: 20px;
;  `}
`;

const AssangeMoon = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${Image2});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  outline: none!;
  z-index: 1;
  align-self: center;
  /* margin-top: 40px; */
    margin-bottom: 620px;
    height: 150px;
    width: 150px;
  ${media.lessThan("medium")`
      /* screen width is less than 768px (medium) */
      display: none;
  `}

  ${media.between("medium", "large")`
      /* screen width is between 768px (medium) and 1170px (large) */
      display: none;
  `}

  ${media.greaterThan("large")`
      /* screen width is greater than 1170px (large) */
  `}
`;

const Assange = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${Image3});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  outline: none!;
  z-index: 1;
  align-self: center;
  /* margin-top: 40px; */
    margin-bottom: 450px;
    height: 300px;
    width: 300px;
  ${media.lessThan("medium")`
      /* screen width is less than 768px (medium) */
      display: block;
      margin-bottom: 50px;
  `}

  ${media.between("medium", "large")`
      /* screen width is between 768px (medium) and 1170px (large) */
      display: block;
      margin-bottom: 50px;
  `}
  ${media.greaterThan("large")`
      /* screen width is greater than 1170px (large) */
      display: none;
  `}
`;

const Moon = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${Image4});
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  outline: none!;
  z-index: 1;
  align-self: center;
  margin-bottom: 40px;
    /* margin-top: 30px; */
    height: 200px;
    width: 200px;
  ${media.lessThan("medium")`
      /* screen width is less than 768px (medium) */
      display: block;
  `}

  ${media.between("medium", "large")`
      /* screen width is between 768px (medium) and 1170px (large) */
      display: block;
  `}
  ${media.greaterThan("large")`
      /* screen width is greater than 1170px (large) */
      display: none;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  margin-bottom: 40px;
  ${media.lessThan("medium")`
  
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  /* margin-right: 100px; */
  `}
`;

const AboutText = styled.h3`
width: 500px;
font-family: 'Montserrat', sans-serif;
font-size: 1.2em;
font-weight: 300;
z-index: 1;
color: white;
line-height: 1px;
align-self: center;
text-align: center;
/* margin-top: 10px; */
${media.lessThan("medium")`
/* font-size: 1.2em; */
/* width: 240px; */
/* text-align: center; */
`}

${media.between("medium", "large")`
/* font-size: 1.4em; */
/* text-align: center; */
`}
`;

const AboutText2 = styled(AboutText)`
font-weight: 500;
`;


const BackgroundImage = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${Image});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  opacity: 60%;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    /* background: rgba(255,255,255,0.3); */
    position: fixed;
  }
`;

export default class WeAre extends Component {
  render() {
    return (
      <Grid>
        <BackgroundImage />
        <OuterContainer>
        <AssangeMoon/>
            <LandingContainer>
            <Moon/>
            <TitleContainer>
                <AboutText>We are the humans </AboutText>
                <AboutText>And we make the laws </AboutText>
                <AboutText>Though when we find power </AboutText>
                <AboutText>We tend to build walls </AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>So every full moon </AboutText>
                <AboutText>Let's let some walls down </AboutText>
                <AboutText>For when we do </AboutText>
                <AboutText>Some space will be found</AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>We are the humans </AboutText>
                <AboutText>We're meant to walk free </AboutText>
                <AboutText>Our roots deep inside us </AboutText>
                <AboutText>Crawled from the sea</AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>We sail Earth together </AboutText>
                <AboutText>A forest of trees </AboutText>
                <AboutText>Let wind fill our sails </AboutText>
                <AboutText2>Set Julian Assange free!</AboutText2>
            </TitleContainer>
            </LandingContainer>
            <LandingContainer>
            <TitleContainer>
                <AboutText>If reporting the truth </AboutText>
                <AboutText>Wherever it's found </AboutText>
                <AboutText>Gets publishers locked </AboutText>
                <AboutText>Behind bars underground </AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>Then what of our nations</AboutText>
                <AboutText>Which claim to be free? </AboutText>
                <AboutText>Enshrined constitutions  </AboutText>
                <AboutText>Of freedoms to speak?</AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>If reporting war crimes </AboutText>
                <AboutText>Gets you locked underground </AboutText>
                <AboutText>Are you not ruled by criminals </AboutText>
                <AboutText>As dark as it sounds?</AboutText>
            </TitleContainer>
            <TitleContainer>
                <AboutText>One question remains </AboutText>
                <AboutText>None can unsee </AboutText>
                <AboutText2>How can America be great</AboutText2>
                <AboutText2>If Assange is not free?</AboutText2>
            </TitleContainer>
            </LandingContainer>
            <Assange/>
        </OuterContainer>

      </Grid>
    );
  }
}

